@tailwind base;
@tailwind components;
@tailwind utilities;





@font-face {
  font-family: 'INTER THIN';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');
  font-display: swap;
}

@font-face {
  font-family: 'INTER EXTRA LIGHT';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap');
  font-display: swap;
}

@font-face {
  font-family: 'INTER LIGHT';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
  font-display: swap;
}

@font-face {
  font-family: 'INTER REGULAR';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
  font-display: swap;
}

@font-face {
  font-family: 'INTER MEDIUM';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
  font-display: swap;
}

@font-face {
  font-family: 'INTER SEMIBOLD';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
  font-display: swap;
}

@font-face {
  font-family: 'INTER BOLD';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
  font-display: swap;
}

@font-face {
  font-family: 'INTER EXTRA BOLD';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap');
  font-display: swap;
}
@font-face {
  font-family: 'AEONIK REGULAR';
  src: local('Aeonik'), url('assets/Aeonik fonts/fonnts.com-Aeonik_Regular.ttf') format('woff'),
  url('assets/Aeonik fonts/fonnts.com-Aeonik_Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'AEONIK BOLD';
  src: local('Aeonik'), url('assets/Aeonik fonts/fonnts.com-Aeonik_Bold.ttf') format('woff'),
  url('assets/Aeonik fonts/fonnts.com-Aeonik_Bold.ttf') format('woff2');
}

@font-face {
  font-family: 'AEONIK LIGHT';
  src: local('Aeonik'), url('assets/Aeonik fonts/fonnts.com-Aeonik_Light.ttf') format('woff'),
  url('assets/Aeonik fonts/fonnts.com-Aeonik_Light.ttf') format('woff2');

}

body {
  margin: 0;
  font-family: 'INTER', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #01000E;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

